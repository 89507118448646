@import '../../../styles/customMediaQueries.css';

.root {
}

.submittedContent {
  margin-top: 24px;
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 16px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 24px 0 48px 0;
}

.error {
  color: var(--colorFail);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.buttonsFlex {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
}

.spinner {
  height: 24px;
  width: 24px;

  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}
